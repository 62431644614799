
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'kt-drawer-addProductInfo',
  components: { Field },

  data() {
    return {
      load: false,
      userInfo: '',
      formData: {
        // product_category_id: '',
        id:'',
        finman_group: '',
        finman_ledger: '',
        name: '',
        address: '',
        phone: '',
        fax: '',
        email: '',
        contact_person: '',
        bin: '',
        enlistment_number: '',
        nid: '',
        tin: '',
        supplier_type: '',
        bank_account_number: '',
        pay_terms: '',
        country_name: 'Bangladesh',
        dc:'',
        op_balance:''
      },

      countryList: [] as any,
      finmanLedgerList: [] as any,
      finmanGroupList: [] as any,
      loading: false,
    };
  },
  async created() {
    this.load = true;
    await this.getSupplierLedgers();
    await this.getSupplierGroups();
    // await this.getProductModelData();
    await this.getCountryList();
    this.loading=true;
    this.emitter.on("supplier-edit-type", async (data) => {
      this.formData = data;
      this.formData.dc= data.op_balance_dc;
      console.log(data);
      this.loading=false;
    });

    // let user_id = VueCookieNext.getCookie('_seip_user');
    // this.userInfo = user_id.id;
  },
  methods: {
    async getSupplierLedgers() {
      this.load = true;
      const targetUrl =
        ApiService.FINMAN_API_URL + '/inventory/getSupplierLedgers';
      axios
        .get(`${targetUrl}`)
        .then((response) => {
          this.finmanLedgerList = response.data;
        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    },
    async getSupplierGroups() {
      this.load = true;
      const targetUrl =
        ApiService.FINMAN_API_URL + '/inventory/getSupplierGroups';
      axios
        .get(`${targetUrl}`)
        .then((response) => {
          this.finmanGroupList = response.data;
        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    },
    async getCountryList() {
      this.load = true;
      await ApiService.get('finmanInvoice/product/countryList')
        .then((response) => {
          this.countryList = response.data.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },

    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }

      this.loading = true;
      await ApiService.post('finmanInvoice/supplier/save', formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit('supplier-added', true);
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.formData = {
                id:'',
                finman_group:'',
                finman_ledger: '',
                name: '',
                address: '',
                phone: '',
                fax: '',
                email: '',
                contact_person: '',
                bin: '',
                enlistment_number: '',
                nid: '',
                tin: '',
                supplier_type: '',
                bank_account_number: '',
                pay_terms: '',
                country_name: '',
                op_balance:'',
                dc:''
              };
              // this.category_name = '';
              // this.code = '';
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
