export const apiEndpoint = {
    data() {
      return {
        VUE_APP_API_URL: "http://dtl-inventory-be.skydigitalbd.com",
        VUE_FINMAN_AUTH_API: "https://dtl.finman-cloud.com/api/inventory/verifyToken"

}
    },
    methods: {
      numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    }
  }